import ng from 'angular';
import { acpFeeplanTableModule } from 'apps/feeplan/components/fee-table';
import { acpFeePlanDomain } from 'components/feeplan-domain';
import acpCore from 'core';
import acpSpeechBubble from 'shared/angularjs/directives/speech-bubble';
import { acpFeeplanBaseComponent } from './acp-feeplan-base-component';
import acpFeeplanBaseContent from './feeplan-base-content.yml';
import './styles/${theme}/core.scss';

const acpFeeplanBaseModule = ng
  .module('acp.component.feeplan-base', [
    acpCore.name,
    acpFeeplanTableModule.name,
    acpFeePlanDomain.name,
    acpSpeechBubble.name
  ])
  .component('acpFeeplanBaseComponent', acpFeeplanBaseComponent)
  .run((contentSectionCache) => {
    'ngInject';

    contentSectionCache.put('components/feeplan-base', acpFeeplanBaseContent);
  });

// Re-export services / ctrls that may be imported from other modules to use for typing information
export { acpFeeplanBaseModule };
