import { IStateService } from 'angular-ui-router';
import {
  ACP_FEEPLAN_CONSTANTS,
  AcpFeeplanModel,
  AvailableFeePlansResponse,
  CurrentFeePlanResponse,
  FeePlanResponse
} from 'components/feeplan-domain';
import { AcpTermsModel } from 'core';
import acpFeeplanBaseComponentTemplate from './templates/acp-feeplan-base-component.html';

interface AcpConfigurationService {
  getBrand(): string;
}

export class AcpFeeplanBaseComponentCtrl {
  public availablePlans: AvailableFeePlansResponse;
  public termUrl: string;
  public subscribedPlan: CurrentFeePlanResponse;
  public planName: string;
  public nextAssessDay: string;
  public brand: string;
  public ACP_FEEPLAN_CONSTANTS = ACP_FEEPLAN_CONSTANTS;
  public isRecurringPlan: boolean = false;
  public availableFeeplans: AvailableFeePlansResponse;
  public hasPremierEligible: boolean = false;
  public showActivateCard: boolean = false;

  constructor(
    nsComponentDecorator,
    private acpFeeplanDomainModel: AcpFeeplanModel,
    private acpTermsModel: AcpTermsModel,
    private acpConfigurationService: AcpConfigurationService,
    private acpCoreDispatcher: any,
    private $state: IStateService
  ) {
    'ngInject';
    // If methods such as $onValue is needed:
    nsComponentDecorator(this, this);
  }

  public $onInit() {
    this.acpTermsModel.getTermsUrl().then((url) => (this.termUrl = url));
    this.brand = this.acpConfigurationService.getBrand();
    this.getSubscribedPlan();
    this.getAvailablePlans();
    this.handleListeners();
    this.acpFeeplanDomainModel
      .fetchPermissions()
      .then(this.updateFeePlanPermissions.bind(this));
  }

  public $onValue?<T>(signal: nsUtils.NsSignal<T>, listener: (data: T) => void);

  public premierEligibleContent(feePlan: FeePlanResponse) {
    return (
      feePlan._cost !== 0 && this.hasPremierEligible && feePlan.months === 0
    );
  }

  public activateCard() {
    this.$state.go('activate');
  }

  private async getSubscribedPlan() {
    try {
      this.subscribedPlan = await this.acpFeeplanDomainModel.get();
      this.nextAssessDay = this.acpFeeplanDomainModel.nth(
        this.subscribedPlan.subscription.assess_day
      );
      this.isRecurringPlan = this.subscribedPlan.plan.flags.some((key) => {
        return key === 'recurring';
      });
    } catch (e) {
      this.subscribedPlan = null;
    }
  }

  private async getAvailablePlans() {
    try {
      this.availablePlans = await this.acpFeeplanDomainModel.getAll();
    } catch (e) {
      this.availablePlans = null;
    }
  }

  private handleListeners(): void {
    this.$onValue(
      this.acpCoreDispatcher.feePlan.feePlanSubscribedSuccessful,
      () => {
        this.acpTermsModel.getTermsUrl().then((url) => (this.termUrl = url));
        this.getSubscribedPlan();
        this.getAvailablePlans();
      }
    );
  }

  private updateFeePlanPermissions(permissions?: nsUtils.NsPermissionResult) {
    this.hasPremierEligible = permissions && permissions.hasPremierEligible;
    this.showActivateCard = permissions && permissions.displayActivateCardCFPB;
  }
}

export const acpFeeplanBaseComponent = {
  bindings: {},
  controller: AcpFeeplanBaseComponentCtrl,
  controllerAs: 'vm',
  require: {},
  template: acpFeeplanBaseComponentTemplate
};
